import './index.css';
import About1 from '../../assets/about/about1.png';
import About2 from '../../assets/about/about2.png';
import About3 from '../../assets/about/about3.png';
import About4 from '../../assets/about/about4.png';
import About5 from '../../assets/about/about5.png';
import About6 from '../../assets/about/about6.png';
import About7 from '../../assets/about/about7.png';
import About8 from '../../assets/about/about8.png';
import About9 from '../../assets/about/about9.png';
import About10 from '../../assets/about/about10.png';
import About11 from '../../assets/about/about11.png';
import About12 from '../../assets/about/about12.png';
import About13 from '../../assets/about/about13.png';
import { useEffect, useRef } from 'react';

export default () => {

    const scrollContainer = useRef(null);

    useEffect(() => {

        const collection = document.getElementsByClassName('AboutUs__wallpaper')
        for (let i = 0; i < collection.length; i++) {
            collection[i].addEventListener("mousedown", e=>{e.preventDefault()}, false);
        }

        // setInterval(()=>{
        //     if(scrollContainer.current !== null){
        //         scrollContainer.current.scrollBy(2, 0);
        //         if(Math.abs(scrollContainer.current.scrollLeft) === scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth || Math.abs(scrollContainer.current.scrollLeft) === scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth - 1) {
        //             scrollContainer.current.scrollTo(0, 0);
        //             console.log('reset')
        //         }
        //     }
        // }, 50)
    }, [])

    return (
        <div className='AboutUs' id='about'>
            <div className='AboutUs__inner'>
                <div className='AboutUs__heading'>Why choose Wallpaper World?</div>
                <marquee scrollamount={12}>
                    <div className='AboutUs__slider' ref={scrollContainer}>
                        <img src={About1} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About2} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About3} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About4} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About5} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About6} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About7} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About8} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About9} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About10} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About11} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About12} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About13} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About1} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About2} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About3} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About4} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About5} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About6} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About7} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About8} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About9} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About10} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About11} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About12} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About13} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About1} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About2} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About3} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About4} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About5} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About6} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About7} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About8} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About9} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About10} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About11} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About12} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About13} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About1} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About2} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About3} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About4} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About5} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About6} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About7} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About8} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About9} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About10} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About11} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About12} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About13} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About1} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About2} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About3} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About4} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About5} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About6} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About7} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About8} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About9} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                        <img src={About10} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '200px'}}/>
                        <img src={About11} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '140px'}}/>
                        <img src={About12} alt='wallpaper' className='AboutUs__wallpaper' />
                        <img src={About13} alt='wallpaper' className='AboutUs__wallpaper' style={{marginTop: '60px'}}/>
                    </div>
                </marquee>
                <div className='AboutUs__desc'>
                    Wallpaper world is one of the leading distributors of the most premium home decor brands with their services across India with a tailored possession of a wide collection of imported modern fine quality wallpapers, roller blinds and wooden floorings for all your spaces to maximize creative choices of their beloved customers.
                    <br />
                    <br />
                    We hold the commitment to effectively furnish every corner of your place with our affordable and luxurious designs to create an eternal alluring impact on your life.
                </div>
            </div>
        </div>
    );
}