import './index.css';
import Button from '../Button';
import { useState } from 'react';
import { send } from 'emailjs-com';
import ReactGA from 'react-ga';

export default () => {

    const [toSend, setToSend] = useState({
        from_name: '',
        message: '',
        reply_to: '',
    });

    const [m, setM] = useState('');

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const handleClick = (e) => {
        e.preventDefault();
        if(toSend.from_name === '' || toSend.reply_to === '' || toSend.message === '' || toSend.phone === '') {
            setM("Fill All Fields Correctly!")
        } else {
            ReactGA.event({category: "ContactUs", action: "Send Clicked"});
            send(
            'service_3k67eur',
            'template_rde7rqw',
            toSend,
            'HJo8I9ZbUq8JQzjbY'
            )
            .then((response) => {
                setM('Message sent successfully!');
                setToSend({
                    from_name: '',
                    message: '',
                    reply_to: '',
                    phone: ''
                })
            })
            .catch((err) => {
                console.log(err);
                setM('FAILED... Error Occured!');
            });
        }
    };

    return (
        <div className='Contactus' id='contact'>
            <div className='Contactus__heading'>Message Us</div>
            <div className='Contactus__namePhone'>
                <input className='Contactus__name' placeholder='Name' type="text" name="from_name" value={toSend.from_name} onChange={handleChange}/>
                <input className='Contactus__phone' placeholder='Phone' type="text" name="phone" value={toSend.phone} onChange={handleChange}/>
            </div>
            <input className='Contactus__email' placeholder='Email' type="email" name="reply_to" value={toSend.reply_to} onChange={handleChange}/>
            <textarea rows={8} className='Contactus__message' placeholder='Message' name="message" value={toSend.message} onChange={handleChange}/>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}><p style={{color: 'white',marginRight: '10%', fontSize: '20px', fontFamily: "'Circular Std', sans-serif"}}>{m}</p><Button color='white' value='SEND' width="30%" clickHandler={handleClick}/></div>
        </div>
    );
}