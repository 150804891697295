import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/marshalLogo.svg';
import './index.css';
import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import ReactGA from "react-ga";

export default () => {
    const [center, setCenter] = useState({lat: 28.48097, lng: 77.08028});
    const [zoom, setZoom] = useState(14);

    return (
        <div className='Footer'>
            <div className='Footer__UpperFooter'>
                <div className='Footer__InnerFooter'>
                    <div className='Footer__heading'>WALLPAPER WORLD<img src={logo} height='20px' style={{marginLeft: '5px'}}/></div>
                    <div className='Footer__desc'>Explore the most exquisite home decor choices with the wallpaper world and get an elevated ambience by adding a touch of art into your magnificent spaces.</div>

                </div>
                <div className='Footer__InnerFooter Footer__QL'>
                    <div className='Footer__subheading'>QUICK LINKS</div>
                    <div>
                        <div className='Footer__Link' onClick={()=>{document.getElementById('about').scrollIntoView({behavior: 'smooth'})}}>About Us</div>
                        <div className='Footer__Link' onClick={()=>{document.getElementById('collection').scrollIntoView({behavior: 'smooth'}); ReactGA.event({category: "Our Collection", action: "Footer Button Clicked"})}}>Our Collections</div>
                        <div className='Footer__Link' onClick={()=>{document.getElementById('customizations').scrollIntoView({behavior: 'smooth'})}}>Customization</div>
                        <div className='Footer__Link' onClick={()=>{document.getElementById('testimonials').scrollIntoView({behavior: 'smooth'})}}>Testimonials</div>
                    </div>
                </div>
                <div className='Footer__InnerFooter'>
                    <div className='Footer__subheading'>FIND OUR STORE</div>
                    <div className='ContactUs__contactDetailsDiv'>
                        <FontAwesomeIcon className='Footer__desc' style={{marginRight: '10px'}} icon={faLocationDot} />
                        <a href='https://maps.app.goo.gl/xLgCpU7UJH3gUgBW6' style={{textDecoration: 'none'}} target="_blank" className='Footer__desc' onClick={()=>{ReactGA.event({category: "Footer", action: "Address Clicked"})}}>Shop No 12, Ground Floor, The Plaza Mall, Gurugram, Haryana 122002</a>
                    </div>
                    <div className='ContactUs__contactDetailsDiv'>
                        <FontAwesomeIcon className='Footer__desc' style={{marginRight: '10px'}} icon={faPhone} />
                        <a href='tel:+919810466770' style={{textDecoration: 'none'}} className='Footer__desc' onClick={()=>{ReactGA.event({category: "Footer", action: "Phone Number Clicked"})}}>+91-98104 66770</a>
                    </div>
                </div>
                <div className='Footer__map'>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                    >
                        <FontAwesomeIcon 
                            lat={28.48097} 
                            lng={77.08028} 
                            icon={faLocationDot}
                            style={{color: 'red', fontSize: '20px', marginTop: '-10px'}}
                        />
                    </GoogleMapReact>
                </div>
            </div>
            <div className='Footer__LowerFooter'>
                <div style={{margin: "0.5% 0"}}>© 2022 Wallpaper World, All Rights Reserved</div>
                <div>Designed By<span style={{cursor: 'pointer'}} onClick={()=>{window.location = "mailto:thefuelmedia.core@gmail.com"; ReactGA.event({category: "Footer", action: "Fuel Media Clicked"})}}> The Fuel Media</span></div>
            </div>
        </div>
    );
}