import './index.css';
import ReactGA from 'react-ga';

export default ({Navref}) => {
    return (
        // <div className='Navbar' ref={Navref}>
        //     <div className='Navbar__contact'>free call us at +91-9810466770</div>
        //     <div className='Navbar__menu'>
        //         <div className='Navbar__menu-item' onClick={()=>{document.getElementById('about').scrollIntoView({behavior: 'smooth'})}}>ABOUT US</div>
        //         <div className='Navbar__menu-item' onClick={()=>{document.getElementById('collection').scrollIntoView({behavior: 'smooth'})}}>OUR COLLECTIONS</div>
        //         <div className='Navbar__menu-item' onClick={()=>{document.getElementById('customizations').scrollIntoView({behavior: 'smooth'})}}>CUSTOMIZATION</div>
        //         <div className='Navbar__menu-item' onClick={()=>{document.getElementById('testimonials').scrollIntoView({behavior: 'smooth'})}}>TESTIMONIALS</div>
        //         <button className='Navbar__menu-button' onClick={()=>{document.getElementById('contact').scrollIntoView({behavior: 'smooth'})}}>CONTACT US</button>
        //     </div>
        // </div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light Navbar" ref={Navref}>
            <a href="#" className='navbar-brand Navbar__contact'>Call us at +91-9810466770</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav mr-auto Navbar__menu">
                    <li><div className='Navbar__menu-item' onClick={()=>{document.getElementById('about').scrollIntoView({behavior: 'smooth'})}}>ABOUT US</div></li>
                    <li><div className='Navbar__menu-item' onClick={()=>{document.getElementById('collection').scrollIntoView({behavior: 'smooth'}); ReactGA.event({category: "Our Collection", action: "Navbar Button Clicked"})}}>OUR COLLECTIONS</div></li>
                    <li><div className='Navbar__menu-item' onClick={()=>{document.getElementById('customizations').scrollIntoView({behavior: 'smooth'})}}>CUSTOMIZATION</div></li>
                    <li><div className='Navbar__menu-item' onClick={()=>{document.getElementById('testimonials').scrollIntoView({behavior: 'smooth'})}}>TESTIMONIALS</div></li>
                    <li><button className='Navbar__menu-button' onClick={()=>{document.getElementById('contact').scrollIntoView({behavior: 'smooth'})}}>CONTACT US</button></li>
                </ul>
            </div>
        </nav>
    );
}