import WallpaperDisplaySection from '../wallpaperDisplaySection';
import './index.css';

export default () => {

    const collection1 = [
        require('../../assets/Collection1/individual/1.jpg'),
        require('../../assets/Collection1/individual/2.jpg'),
        require('../../assets/Collection1/individual/3.jpg'),
        require('../../assets/Collection1/individual/4.jpg'),
        require('../../assets/Collection1/individual/5.jpg'),
        require('../../assets/Collection1/individual/6.jpg'),
        require('../../assets/Collection1/individual/7.jpg'),
        require('../../assets/Collection1/individual/8.jpg'),
    ];

    const collection2 = [
        require('../../assets/Collection2/individual/1.jpg'),
        require('../../assets/Collection2/individual/2.jpg'),
        require('../../assets/Collection2/individual/3.jpg'),
        require('../../assets/Collection2/individual/4.jpg'),
        require('../../assets/Collection2/individual/5.jpg'),
        require('../../assets/Collection2/individual/6.jpg'),
        require('../../assets/Collection2/individual/8.jpg'),
    ];

    const collection3 = [
        require('../../assets/Collection3/individual/1.jpg'),
        require('../../assets/Collection3/individual/2.jpg'),
        require('../../assets/Collection3/individual/3.jpg'),
        require('../../assets/Collection3/individual/4.jpg'),
        require('../../assets/Collection3/individual/5.jpg'),
        require('../../assets/Collection3/individual/6.jpg'),
        require('../../assets/Collection3/individual/7.jpg'),
        require('../../assets/Collection3/individual/8.jpg'),
    ];

    const collection4 = [
        require('../../assets/Collection4/individual/1.jpg'),
        require('../../assets/Collection4/individual/2.jpg'),
        require('../../assets/Collection4/individual/3.jpg'),
        require('../../assets/Collection4/individual/4.jpg'),
        require('../../assets/Collection4/individual/5.jpg'),
        require('../../assets/Collection4/individual/6.jpg'),
        require('../../assets/Collection4/individual/7.jpg'),
    ];

    const collection5 = [
        require('../../assets/Collection5/individual/1.jpg'),
        require('../../assets/Collection5/individual/2.jpg'),
        require('../../assets/Collection5/individual/3.jpg'),
        require('../../assets/Collection5/individual/4.jpg'),
        require('../../assets/Collection5/individual/5.jpg'),
        require('../../assets/Collection5/individual/6.jpg'),
        require('../../assets/Collection5/individual/7.jpg'),
        require('../../assets/Collection5/individual/8.jpg'),
    ];

    const collection1mockups = [
        require('../../assets/Collection1/individual/km-1.png'),
        require('../../assets/Collection1/individual/km-2.png'),
        require('../../assets/Collection1/individual/km-3.png'),
        require('../../assets/Collection1/individual/km-4.png'),
        require('../../assets/Collection1/individual/km-5.png'),
        require('../../assets/Collection1/individual/km-6.png'),
        require('../../assets/Collection1/individual/km-7.png'),
        require('../../assets/Collection1/individual/km-8.png'),
    ];

    const collection2mockups = [
        require('../../assets/Collection2/individual/ms-1.jpg'),
        require('../../assets/Collection2/individual/ms-2.jpg'),
        require('../../assets/Collection2/individual/ms-3.png'),
        require('../../assets/Collection2/individual/ms-4.jpg'),
        require('../../assets/Collection2/individual/ms-5.png'),
        require('../../assets/Collection2/individual/ms-6.jpg'),
        require('../../assets/Collection2/individual/ms-8.png'),
    ];

    const collection3mockups = [
        require('../../assets/Collection3/individual/beta-1.png'),
        require('../../assets/Collection3/individual/beta-2.png'),
        require('../../assets/Collection3/individual/beta-3.png'),
        require('../../assets/Collection3/individual/beta-4.png'),
        require('../../assets/Collection3/individual/beta-5.png'),
        require('../../assets/Collection3/individual/beta-6.png'),
        require('../../assets/Collection3/individual/beta-7.png'),
        require('../../assets/Collection3/individual/beta-8.png'),
    ];

    const collection4mockups = [
        require('../../assets/Collection4/individual/sans-1.png'),
        require('../../assets/Collection4/individual/sans-2.png'),
        require('../../assets/Collection4/individual/sans-3.png'),
        require('../../assets/Collection4/individual/sans-4.png'),
        require('../../assets/Collection4/individual/sans-5.png'),
        require('../../assets/Collection4/individual/sans-6.png'),
        require('../../assets/Collection4/individual/sans-7.png'),
    ];

    const collection5mockups = [
        require('../../assets/Collection5/individual/kid-1.png'),
        require('../../assets/Collection5/individual/kid-2.png'),
        require('../../assets/Collection5/individual/kid-3.png'),
        require('../../assets/Collection5/individual/kid-4.png'),
        require('../../assets/Collection5/individual/kid-5.png'),
        require('../../assets/Collection5/individual/kid-6.png'),
        require('../../assets/Collection5/individual/kid-7.png'),
        require('../../assets/Collection5/individual/kid-8.png'),
    ];

    return (
        <>
            <WallpaperDisplaySection collectionNo="1" images={collection1} mockups={collection1mockups} imageName="km.png" collectionName="Krsna Mehta" desc="Krsna Mehta, one of the world’s renowned interior designers, known for his quintessential taste in textile and art is focused on nurturing relationships and is striking today’s India with his artwork and flabbergasting designing skills."/>
            <WallpaperDisplaySection collectionNo="2" images={collection2} mockups={collection2mockups} imageName="ms.png" collectionName="Magnificent South" desc="Magnificent south is a sumptuous slice of South India, particularly Karnataka’s resplendent Mysore Palace and the Chettiar mansions of Tamil Nadu. The magnificence of the south is perfectly portrayed and formulated by Asian Paints."/>
            <WallpaperDisplaySection collectionNo="3" images={collection3} mockups={collection3mockups} imageName="beta.png" collectionName="Beta" desc="AdaWall is the largest wallpaper manufacturer in Turkey and exports worldwide. The harmony between craftsmanship and advanced technology that creates lasting value is Adawall's product philosophy towards creating the best wallpaper designs. The extravagant collection combines unique designs with superior quality and offers excellence."/>
            <WallpaperDisplaySection collectionNo="4" images={collection4} mockups={collection4mockups} imageName="sans.png" collectionName="Sanskriti" desc="Sanskriti is our heritage which preserves the essence of our ancestors and keeps their art alive. This collection gives your walls an essence of the tribal art, Royal India structures and many more authentic Indian cultural elements which amalgamates various cultures across India."/>
            <WallpaperDisplaySection collectionNo="5" images={collection5} mockups={collection5mockups} imageName="kids.png" collectionName="Kids World" desc="This versatile collection for our little ones offers an extravagant scope for customization and provides a joyful ambience for your kids room. The exquisitivity of the designs inculcates a cheerful mood and elevates the creativity of your child."/>
        </>
    );
}