import './index.css';
import Button from '../Button';

export default () => {
    return(
        <div className='Landingpage'>
            <div className='LandingPage__heading'>WALLPAPER WORLD</div>
            <div className='LandingPage__desc'>Explore the most exquisite home decor choices with the wallpaper world and get an elevated ambience into your magnificent spaces.</div>
            <Button value={"VIEW MORE"} clickHandler={()=>{document.getElementById('collection').scrollIntoView({behavior: 'smooth'})}} width="200px" color="#8B191C"/>
        </div>
    );
}