import Button from '../Button';
import './index.css';
import C1 from '../../assets/customization1.jfif';
import C2 from '../../assets/customization2.png';
import C3 from '../../assets/customization3.jpg';

export default () => {
    return (
        <div className='Customization' id='customizations'>
            <div className='Customization__inner'>
                <div className='Customization__heading'>Customization</div>
                <div className='Customization__desc'>Get your favorite designs and snapshots converted into blinds for windows and designer wallpapers. Wallpaper World can also help you to inculcate your imagination into a living dream for your most significant spaces. Just send us your designs and watch your vision coming live with us!</div>
                <div className='Customization__imageContainer'>
                    <img className='Customization__img' src={C1} alt="customization"/>
                    <img className='Customization__img' src={C2} alt="customization"/>
                    <img className='Customization__img' src={C3} alt="customization"/>
                </div>
                <div className="Customization__btnCon">
                    <Button value="Get Your Customization Now!" width="400px" color="white" clickHandler={()=>{window.open("https://forms.gle/2CRyqrAhjuDyVVJr7", "_blank")}}/>
                </div>
            </div>
        </div>
    );
}