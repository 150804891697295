import { useRef, useEffect } from 'react';
export default ({value, name, icon, delimiter=''}) => {

    const currentRef = useRef(null);

    function animate(obj, initVal, lastVal, duration) {
        let startTime = null;
        let currentTime = Date.now();
        const step = (currentTime) => {
            if (!startTime) {
                startTime = currentTime ;
            }
            const progress = Math.min((currentTime - startTime)/ duration, 1);
            obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal) + delimiter;
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(window.requestAnimationFrame(step));
            }
        };
        window.requestAnimationFrame(step);
     }

     useEffect(()=>{
        function handleIntersection(entries) {
            entries.map((entry) => {
              if (entry.isIntersecting) {
                animate(currentRef.current, 0, value, 1500)
              }
            });
          }
        let opts = {
            threshold: 0.5
        }
        const observer = new IntersectionObserver(handleIntersection, opts);
        observer.observe(currentRef.current);
     }, [])

    return (
        <div className='Stores__container'>
            <img className='Stores__icon' src={icon} alt="icon" />
            <div className='Stores__counter' ref={currentRef}>0</div>
            <div className='Stores__name'>{name}</div>
        </div>
    );
}