import { useState } from 'react';
import './index.css';
import Button from '../Button';
import Drawer from '../Drawer';


export default ({imageName, collectionName, desc, collectionNo, images, mockups}) => {
    const [isVisible, setVisible] = useState(false);

    const onclick = ()=>{
        setVisible(prev => (!prev));
        setTimeout(()=>(document.getElementById(collectionName).scrollIntoView({behavior: 'smooth'})), 0);
    }

    return(
        <div style={{marginBottom: '96px'}} id="wallpapers">
            <div className="WallpaperDisplay" style={{backgroundImage: `url(${require(`../../assets/Collection${collectionNo}/landing/${imageName}`)})`}}>
                <div className='WallpaperDisplay__div'>
                    <div className='WallpaperDisplay__heading'>{collectionName}</div>
                    <Button value={"View More"} clickHandler={onclick} width="200px" color="white"/>
                </div>
            </div>
            {isVisible && <Drawer id={collectionName} images={images} mockups={mockups} desc={desc}/>}
        </div>
    );
}