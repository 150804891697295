import './index.css';
import Store from './store';
import Home from '../../assets/icons/home.svg';
import Star from '../../assets/icons/star.svg';
import Employees from '../../assets/icons/employees.svg';

export default () => {
    return(
        <div className='Stores'>
            <Store icon={Home} name="COLLECTIONS" value={200} delimiter='+'/>
            <Store icon={Star} name="YEARS OF EXPERIENCE" value={20} delimiter='+'/>
            <Store icon={Employees} name="SATISFIED CUSTOMERS" value={10000} delimiter='+'/>
        </div>
    );
}