import './index.css';
import Testimonial from './testimonial';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default () => {

    const testimonials = [
        {
            image: require("../../assets/testimonials/1.jpg"),
            desc: `I'm happy and satisfied with the living room wallpaper that I bought for myself. The sales team of the wallpaper world helped me to get exactly what I was looking for. The quality of the blinds and wallpaper is excellent and it is very well crafted.`
        },
        {
            image: require("../../assets/testimonials/3.jpg"),
            desc: `I purchased roller blinds from the company which are simply amazing and the quality is also very good. I received my order within a couple of days. The experience of purchasing was also very easy and fascinating.`
        },
        {
            image: require("../../assets/testimonials/4.jpg"),
            desc: `I want to thank wallpaper world for their gorgeous wallpapers that transformed the look of my room. They have a wide variety of designs for wallpaper for walls and blinds. Do check them out.`
        },
        {
            image: require("../../assets/testimonials/5.jpg"),
            desc: `My friend advised me to shop from this company. I checked out their range of textured wood floors. Looking at its reasonable price and quality, I bought it in an instant. I'm happy with my purchase till date.`
        },
        {
            image: require("../../assets/testimonials/6.jpg"),
            desc: `I bought customized anime designer wallpaper for my room. They are so beautiful and vibrant. I'm a big anime freak so I'm totally in love with it."`
        },
        {
            image: require("../../assets/testimonials/7.jpg"),
            desc: `I liked how economical their items are, with no compromise on the quality and range. Bought blinds and flooring. I'm happy with my purchase.`
        },
    ];

    const scrollContainer = useRef(null);

    const handleRight = () => {
        var scrollAmount = 0;
        var scrollMax = scrollContainer.current.clientWidth
        scrollContainer.current.scrollTo({
            top: 0,
            left: Math.max(scrollAmount += 500, scrollMax),
            behavior: 'smooth'
          });
    }

    const handleLeft = () => {
        var scrollAmount = 0;
        var scrollMin = 0
        scrollContainer.current.scrollTo({
            top: 0,
            left: Math.max(scrollAmount -= 500, scrollMin),
            behavior: 'smooth'
          });
    }

    return (
        <div className='Testimonials' id="testimonials">
            <div className='Testimonials__inner'>
                <div className='Testimonials__quote'><span style={{marginTop: '0.3em'}}>“</span></div>
                <div className='Testimonials__textContainer'>
                    <div className='Testimonials__heading'>Testimonials</div>
                    <div className='Testimonials__desc'>Hear about our client's experiences which helped them elevate their ambience with Wallpaper World.</div>
                </div>
                <div className='Testimonial__sliderDiv'>
                    <div className='Testimonial__leftBtn' onClick={handleLeft}><FontAwesomeIcon icon={faChevronLeft}/></div>
                    <div className='Testimonial__rightBtn' onClick={handleRight}><FontAwesomeIcon icon={faChevronRight}/></div>
                    <div className='Testimonial__slider' ref={scrollContainer}>
                        {testimonials.map(t => <Testimonial image={t.image} desc={t.desc} />)}
                    </div>
                </div>
            </div>
        </div>
    );
}