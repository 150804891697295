import './index.css';
import { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default ({images, desc, mockups, id})=>{

    const [bigImage, setBigImage] = useState(mockups[0]);

    const scrollContainer = useRef(null);

    const handleRight = () => {
        var scrollAmount = 0;
        var scrollMin = 0
        var scrollMax = scrollContainer.current.clientWidth
        scrollContainer.current.scrollTo({
            top: 0,
            left: Math.max(scrollAmount += 500, scrollMax),
            behavior: 'smooth'
          });
    }

    const handleLeft = () => {
        var scrollAmount = 0;
        var scrollMin = 0
        var scrollMax = scrollContainer.current.clientWidth
        scrollContainer.current.scrollTo({
            top: 0,
            left: Math.max(scrollAmount -= 500, scrollMin),
            behavior: 'smooth'
          });
    }

    return (
        <div className="WallpaperDisplay__drawer" id={id}>
            <div className='WallpaperDisplay__innerdrawer'>
                <div className='WallpaperDisplay__bigImageContainer' style={!desc ? {justifyContent: 'center'} : {}}>
                    <img src={bigImage} alt='image' className='WallpaperDisplay__bigImage'/>
                    {desc && <div className='WallpaperDisplay__text'>{desc}</div>}
                </div>
                <div style={{position: 'relative',marginTop: '32px'}} className='WallpaperDisplay__sliderContainer'>
                    <div className='WallpaperDisplay__leftBtn' onClick={handleLeft}><FontAwesomeIcon icon={faChevronLeft}/></div>
                    <div className='WallpaperDisplay__rightBtn' onClick={handleRight}><FontAwesomeIcon icon={faChevronRight}/></div>
                    <div className='WallpaperDisplay__slider' ref={scrollContainer}>
                        {images.map((image, index) => (<div className='WallpaperDisplay__imgCon'><img className='WallpaperDisplay__image' alt="small images" src={image} width="120%" height="120%" onClick={()=>{setBigImage(mockups[index])}}/></div>))}
                    </div>
                </div>
            </div>
        </div>
    );
}