import './App.css';
import Navbar from './components/navbar';
import Uppernav from './components/uppernav';
import { useRef } from 'react';
import Stores from './components/stores';
import AboutUs from './components/AboutUs';
import LandingPage from './components/LandingPage';
import Collections from './components/Collections';
import Customization from './components/Customization';
import Testimonials from './components/testimonials';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import backgroundImage from './assets/background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

function App() {

  const TRACKING_ID = "UA-241660133-1";
  ReactGA.initialize(TRACKING_ID);

  const Navref = useRef(null);
  const appref = useRef(null);
  const buttonRef = useRef(null);

  const handleScroll = () => {
      var isPositionFixed = Navref.current.style.position === 'fixed';
      var elementTop = Navref.current.offsetTop - appref.current.scrollTop;
      if (elementTop < 0 && !isPositionFixed){ 
        Navref.current.style.position = 'fixed';
        Navref.current.style.top = '0px';
        Navref.current.style.width = 'calc(100% - 16px)';
        buttonRef.current.style.display = "block";
      }
      if (elementTop >= 0 && isPositionFixed){
        Navref.current.style.position = '';
        Navref.current.style.width = '100%';
        buttonRef.current.style.display = "none";
      } 
  }

  return (
    <>
    <button className='scrollTopBtn' onClick={()=>{document.getElementById('uppernav').scrollIntoView({behavior: 'smooth'})}} ref={buttonRef}><FontAwesomeIcon icon={faArrowUp}/></button>
    <div className="App" ref={appref} onScroll={handleScroll} style={{backgroundImage: `url(${backgroundImage})`}}>
      <Uppernav/>
      <Navbar Navref={Navref}/>
      <LandingPage />
      <AboutUs />
      <Stores />
      <Collections />
      <Customization />
      <Testimonials />
      <ContactUs />
      <Footer />
    </div>
    </>
  );
}

export default App;
