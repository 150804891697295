import './index.css';
import wallpapers from '../../assets/w1.jpg';
import woodenflooring from '../../assets/w2.PNG';
import windowblinds from '../../assets/w3.PNG';
import { useRef, useState } from 'react';
import WallpaperDisplaySection from '../wallpaperDisplaySection';
import Wallpapers from '../Wallpapers';
import DisplayImages from '../DisplayImages';
import Drawer from '../Drawer';

export default () => {
    const w1ref = useRef(null);
    const w2ref = useRef(null);
    const w3ref = useRef(null);
    const [isVisibleW1, setVisibleW1] = useState(false);
    const [isVisibleW2, setVisibleW2] = useState(false);
    const [isVisibleW3, setVisibleW3] = useState(false);

    const [collection, setCollection] = useState(1);

    const handleMouseEnter = (ref, setFunction) => {
        ref.current.style.opacity = '0.5';
        setFunction(true);
    }

    const handleMouseLeave = (ref, setFunction) => {
        ref.current.style.opacity = '1';
        setFunction(false);
    }

    const flooringImages = [
        require('../../assets/flooring/1.jpg'),
        require('../../assets/flooring/2.jpg'),
        require('../../assets/flooring/3.png'),
        require('../../assets/flooring/4.png'),
        require('../../assets/flooring/5.png'),
        require('../../assets/flooring/6.png'),
        require('../../assets/flooring/7.png'),
        require('../../assets/flooring/8.png'),
    ]

    const blindsMockups = [
        require('../../assets/blinds/1.png'),
        require('../../assets/blinds/2.png'),
        require('../../assets/blinds/3.png'),
        require('../../assets/blinds/4.png'),
        require('../../assets/blinds/5.png'),
        require('../../assets/blinds/6.png'),
        require('../../assets/blinds/7.png'),
    ];

    const blindsImages = [
        require('../../assets/blinds/11.png'),
        require('../../assets/blinds/22.png'),
        require('../../assets/blinds/33.png'),
        require('../../assets/blinds/44.png'),
        require('../../assets/blinds/55.jpg'),
        require('../../assets/blinds/66.png'),
        require('../../assets/blinds/77.png'),
    ];

    return (
        <>
            <div className='Collections' id='collection'>
                <div className='Collections__heading'>Our Collections</div>
                <div className='Collections__innerContainer'>
                    <div className='Collections__wallpaperDiv' onClick={()=>{setCollection(1); setTimeout(()=>{document.getElementById('wallpapers').scrollIntoView({behavior: 'smooth'})},0)}} onMouseEnter={()=>handleMouseEnter(w1ref, setVisibleW1)} onMouseLeave={()=>handleMouseLeave(w1ref, setVisibleW1)}>
                        <img ref={w1ref} className='Collections__img' src={wallpapers} alt='wallpapers' height='100%' width='100%'/>
                        {isVisibleW1 && <p className='Collections__wallpaperTitle'>Wallpapers</p>}
                    </div>
                    <div className='Collections__wallpaperDiv' onClick={()=>{setCollection(2); setTimeout(()=>{document.getElementById('woodenFloorings').scrollIntoView({behavior: 'smooth'})},0)}} onMouseEnter={()=>handleMouseEnter(w2ref, setVisibleW2)} onMouseLeave={()=>handleMouseLeave(w2ref, setVisibleW2)}>
                        <img ref={w2ref} className='Collections__img' src={woodenflooring} alt='wooden flooring' height='100%' width='100%'/>
                        {isVisibleW2 && <p className='Collections__wallpaperTitle'>Wooden<br />Flooring</p>}
                    </div>
                    <div className='Collections__wallpaperDiv' onClick={()=>{setCollection(3); setTimeout(()=>{document.getElementById('windowBlinds').scrollIntoView({behavior: 'smooth'})},0)}} onMouseEnter={()=>handleMouseEnter(w3ref, setVisibleW3)} onMouseLeave={()=>handleMouseLeave(w3ref, setVisibleW3)}>
                        <img ref={w3ref} className='Collections__img' src={windowblinds} alt='window blinds' height='100%' width='100%'/>
                        {isVisibleW3 && <p className='Collections__wallpaperTitle'>Window<br />Blinds</p>}
                    </div>
                </div>
            </div>
            {collection === 1 && <Wallpapers />}
            {collection === 2 && <Drawer id="woodenFloorings" images={flooringImages} mockups={flooringImages} />}
            {collection === 3 && <Drawer id="windowBlinds" images={blindsImages} mockups={blindsMockups}/>}
        </>
    );
}